<style lang="scss">
.header {
  @apply lg:pb-4 relative flex w-full justify-between items-center pt-1 lg:pt-2 z-9999;
}
.header__logos {
  @apply flex items-center;
  &-split {
    @apply font-druk_text_cy text-12 lg:text-18 mr-3 lg:mr-8;
  }
}
.game-progress {
  @apply font-graphik_lv_regular absolute lg:top-1;
  color: rgba(255, 255, 255, 0.5);
  left: calc(50% - 53px);
  &__value {
    font-size: 18px;
    line-height: 26px;
  }
  &__title {
    font-size: 12px;
    line-height: 16px;
  }
}
.header-icon-logo {
  width: 96px;
}

.header-activity-logo {
  width: 103px;
}

@screen lg {
  .header-icon-logo {
    width: auto;
  }
  .header-activity-logo {
    width: auto;
  }
}
.header-login {
  color: #00c1f5;
  transition: 0.25s;
  &:hover {
    color: #ffffff;
  }
}
</style>

<template>
  <div
    class="header"
    :style="{
      borderBottom: isAuthenticated ? '' : '1px solid rgba(255,255,255,0.4)',
    }"
  >
    <div class="header__logos">
      <template v-if="!isAuthenticated">
        <icon-logo class="header-icon-logo" />
        <div class="header__logos-split">X</div>
        <icon-activity-logo class="header-activity-logo" />
      </template>
      <router-link v-else :to="{ name: 'Issues' }"
        ><icon-activity-logo
      /></router-link>
    </div>
    <ButtonAuth v-if="!isAuthenticated" type="ghost">
      <span class="hidden lg:inline-block">Войти</span
      ><icon-login class="inline-block lg:hidden header-login mr-2"
    /></ButtonAuth>
    <template v-if="isAuthenticated">
      <!-- <div
        class="game-progress cursor-default hidden md:inline-block"
        name="tipTrigger"
      >
        <div class="game-progress__value">
          <template v-if="finalStatus && finalStatus.is_final_active">
            31 Октября в 14:00
          </template>
          <template v-else>
            <span style="color: white">{{
              taskSolved > 2 ? 2 : taskSolved
            }}</span>
            /
            <span style="color: white">2</span>
          </template>
        </div>
        <div class="game-progress__title">
          {{
            finalStatus && finalStatus.is_final_active
              ? 'начало трансляции'
              : 'решено до финала'
          }}
        </div>
      </div>
      <tippy
        v-if="!finalStatus || !finalStatus.is_final_active"
        to="tipTrigger"
        arrow
        placement="bottom"
        animation="perspective"
      >
        Чтобы попасть в финал,<br />нужно решить два любых задания
      </tippy> -->
      <user-badge />
    </template>
  </div>
</template>

<script>
import useUser from '../composables/useUser'
import IconLogo from './Icons/IconLogo.vue'
import IconActivityLogo from './Icons/IconActivityLogo.vue'
import UserBadge from './UserBadge.vue'
import useGame from '@/composables/useGame'
import ButtonAuth from './ButtonAuth.vue'
import IconLogin from './Icons/IconLogin.vue'

export default {
  name: 'Header',
  components: { IconLogo, UserBadge, IconActivityLogo, ButtonAuth, IconLogin },
  data() {
    return {}
  },
  setup() {
    const { isAuthenticated } = useUser()
    const { taskSolved, finalStatus } = useGame()
    return {
      isAuthenticated,
      taskSolved,
      finalStatus,
    }
  },
  props: {},
  created() {},
  mounted() {},
  methods: {},
  computed: {},
}
</script>
