<style lang="scss"></style>

<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="24" height="24" rx="12" fill="#141414" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.6998 7.22265L13.4256 6.8821C13.2593 6.69309 12.9684 6.49869 12.553 6.2989C12.1349 6.09935 11.677 5.99718 11.2137 6.00006C10.2602 6.00006 9.39687 6.41468 8.62381 7.24393C7.86589 8.03205 7.44898 9.08704 7.46337 10.1804C7.44974 11.2728 7.8666 12.3267 8.62381 13.1142C9.39687 13.9435 10.2602 14.3581 11.2137 14.3581C11.5136 14.359 11.8119 14.3157 12.0992 14.2296C12.3409 14.1625 12.5729 14.0647 12.7896 13.9384C12.9632 13.8311 13.1273 13.7092 13.28 13.574C13.4327 13.4383 13.5326 13.3441 13.5797 13.2913C13.6266 13.2385 13.7074 13.1585 13.7296 13.1211V15.3551H16.0283V6.15075H13.6998V7.22265ZM13.097 11.6286C12.922 11.8174 12.7098 11.9679 12.4737 12.0705C12.2376 12.173 11.9828 12.2255 11.7254 12.2245C11.4749 12.2283 11.2264 12.1783 10.9968 12.0778C10.7673 11.9774 10.5619 11.8288 10.3947 11.6422C10.0297 11.2562 9.84725 10.7675 9.84725 10.1761C9.84725 9.58468 10.0289 9.09655 10.3921 8.71172C10.5603 8.52581 10.7662 8.37804 10.9962 8.27832C11.2262 8.17861 11.4748 8.12928 11.7254 8.13363C11.9828 8.13298 12.2375 8.18559 12.4735 8.28816C12.7096 8.39072 12.9219 8.54102 13.097 8.7296C13.4733 9.11847 13.6837 9.6384 13.6837 10.1795C13.6837 10.7206 13.4733 11.2406 13.097 11.6294V11.6286ZM9.61738 17.8309H13.7296V15.3568H9.61738V17.8309Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconGeeckoRounded',
  components: {},
  data() {
    return {}
  },
  props: {},
  created() {},
  mounted() {},
  methods: {},
  computed: {},
}
</script>
