<template>
  <!-- nothing -->
</template>

<script>
import useUser from '../composables/useUser'

export default {
  name: 'GameAuth',
  components: {},
  data() {
    return {
      w: null,
      wInterval: null,
      messageReceived: false,
    }
  },
  setup() {
    const { setAuthToken, updateUser } = useUser()
    return {
      setAuthToken,
      updateUser,
    }
  },
  computed: {
    authUrl() {
      const location = window.location.href
      return `${process.env.VUE_APP_SERVER_URL}/oauth/redirect?redirect_url=${location}`
    },
  },
  methods: {
    onMessage(event) {
      if (
        event &&
        event.data &&
        event.data.source &&
        event.data.source.startsWith('vue')
      )
        return
      if (this.messageReceived) return
      this.$log('onMessage', event)
      if (event && event.data && event.data.token) {
        this.messageReceived = true
        this.setAuthToken(event.data.token)
        this.updateUser()
        this.w.close()
        window.location.reload()
      }
    },
    start() {
      this.$log('start', window.location.href)
      const left = (window.screen.width - 1200) / 2
      const top = (window.screen.height - 800) / 2
      let windowFeatures = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=1200,height=800,top=${top},left=${left}`
      this.w = window.open(this.authUrl, 'Auth', windowFeatures)
      // Listen for window.CLOSE
      this.wInterval = setInterval(() => {
        this.$log('wIterval', this.wInterval, this.w.closed)
        if (this.w.closed) {
          clearInterval(this.wInterval)
          if (this.messageReceived) this.$emit('done')
          else this.$emit('closed')
        }
      }, 500)
    },
  },
  mounted() {
    this.$log('mounted', window.location.href)
    window.addEventListener('message', this.onMessage)
    this.start()
  },
  beforeDestroy() {
    this.$log('beforeDestroy', this.wInterval)
    window.removeEventListener('message', this.onMessage)
    if (this.wInterval) clearInterval(this.wInterval)
  },
}
</script>
