export const DOCS = {
  RULES: 'https://geecko.notion.site/e6e68d18e1084281b59526f1d30f7558',
  AGREEMENT: 'https://geecko.notion.site/b2342b64f6b945d3b8f7c31ef6f6d721',
  PRIVACY: 'https://geecko.notion.site/673b1a5088314734ae91c4e167d04597',
}

export const REWARDS = [
  {
    placeId: 1,
    place: '1 место',
    title: 'Игровая приставка Sony PlayStation 5',
    image: '/images/sony.png',
  },
  {
    placeId: 2,
    place: '2 место',
    title: 'Segway Ninebot KickScooter F30',
    image: '/images/segway.png',
  },
  {
    placeId: 3,
    place: '3 место',
    title: 'Планшет Apple iPad 10, 32 Гб',
    image: '/images/ipad.png',
  },
  {
    placeId: 4,
    place: '4 место',
    title: 'Наушники Samsung Galaxy Buds Pro',
    image: '/images/headphones.png',
  },
  {
    place: 'с 5 по 8 место',
    title: 'Рюкзак Wenger MX Professional 16”',
    image: '/images/backpack.png',
  },
  {
    place: 'с 9 по 16 место',
    title: 'Мерч на любой вкус и цвет',
    image: '/images/jacket.png',
  },
  {
    place: '2 бонусных приза',
    title: 'Apple Watch Series 6',
    image: '/images/applewatch.png',
  },
]
