<style lang="scss">
.layout {
  @apply flex flex-col overflow-hidden;
  min-height: 100vh;
  background-color: #141414;
  background-image: url('/images/back-landing.svg');
  background-size: 1867px;
  background-repeat: no-repeat;
  background-position: center top 200px;
  &--game {
    background-image: url('/images/grid.svg'), url('/images/game-bg.png');
    background-repeat: repeat, no-repeat;
    background-position: center top;
    background-size: 59px, 2452px;
  }
}
.layout-content {
  @apply mx-auto px-4 lg:px-10 w-full;
  max-width: 670px;

  &--full {
    max-width: 100%;
  }

  &--footer {
    @apply px-0;
    background: linear-gradient(0deg, #000000, #000000),
      linear-gradient(0deg, #0c0c0c, #0c0c0c),
      linear-gradient(0deg, #f4f4f4, #f4f4f4);
  }
}
.layout-main {
  @apply flex-grow;
}

@screen lg {
  .layout-content {
    max-width: 1280px;
  }
}
</style>

<template>
  <div class="layout" :class="{ 'layout--game': isGame }">
    <div class="layout-content">
      <Header />
    </div>
    <div class="layout-content layout-main">
      <router-view />
    </div>
    <div class="layout-content layout-content--full layout-content--footer">
      <Footer />
    </div>
  </div>
</template>

<script>
import Footer from '../components/Footer.vue'
import Header from '../components/Header.vue'
export default {
  name: 'MainLayout',
  components: { Header, Footer },
  data() {
    return {}
  },
  props: {
    isGame: { type: Boolean, default: false },
  },
  created() {},
  mounted() {},
  methods: {},
  computed: {},
}
</script>
