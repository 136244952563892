<template>
  <svg
    width="145"
    height="20"
    viewBox="0 0 145 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.09874 3.46823C6.77043 3.46823 6.52851 3.57191 6.37299 3.77927C6.23475 3.96935 6.13107 4.28903 6.06195 4.73831L4.37717 15.3654C4.27349 16.0912 4.54133 16.454 5.18068 16.454C5.73364 16.454 6.07059 16.0912 6.19155 15.3654L7.87634 4.73831C7.9973 3.89159 7.7381 3.46823 7.09874 3.46823ZM6.81363 0.0986328H7.51346C9.01681 0.0986328 10.0882 0.539281 10.7275 1.42055C11.3669 2.30183 11.5742 3.49416 11.3496 4.9975L9.74256 15.1581C9.20689 18.3203 7.78994 19.9013 5.49172 19.9013H4.79188C3.34038 19.9013 2.26903 19.4693 1.57783 18.6054C0.903919 17.7414 0.687921 16.5491 0.929839 15.0285L2.51094 5.02342C2.77014 3.38184 3.21942 2.15495 3.85877 1.34279C4.51541 0.513362 5.50036 0.0986328 6.81363 0.0986328Z"
      fill="#D7D7D7"
    />
    <path
      d="M17.3105 11.8922H15.3665L14.1483 19.6162H10.8047L13.8632 0.331922H18.2955C19.6606 0.331922 20.5851 0.660242 21.0689 1.31688C21.57 1.95623 21.7082 2.898 21.4836 4.14215L20.8615 8.0042C20.6715 9.26562 20.3086 10.2333 19.7729 10.9072C19.2372 11.5638 18.4164 11.8922 17.3105 11.8922ZM16.7403 3.3127L15.8849 8.75587H16.5848C16.9131 8.75587 17.1377 8.67811 17.2587 8.52259C17.3796 8.36707 17.466 8.09923 17.5179 7.71908L18.0363 4.45319C18.1572 3.69287 17.924 3.3127 17.3364 3.3127H16.7403Z"
      fill="#D7D7D7"
    />
    <path
      d="M23.7279 0.331922H30.8558L30.3115 3.72743H26.5532L25.8792 7.92643H28.8859L28.3157 11.3997H25.309L24.5573 16.143H28.549L27.9787 19.6162H20.6694L23.7279 0.331922Z"
      fill="#D7D7D7"
    />
    <path
      d="M32.6307 0.331922H35.767L36.4928 10.3111L38.0739 0.331922H41.0547L37.9961 19.6162H35.145L34.2637 8.88547L32.553 19.6162H29.5722L32.6307 0.331922Z"
      fill="#D7D7D7"
    />
    <path
      d="M46.906 19.6162L49.9646 0.331922H54.4228C57.1012 0.331922 58.2071 1.70569 57.7405 4.45319L56.0298 15.2617C55.8052 16.7824 55.4164 17.8882 54.8634 18.5794C54.3277 19.2706 53.4033 19.6162 52.09 19.6162H46.906ZM52.8158 3.39046L50.7422 16.4022H51.442C51.8394 16.4022 52.1073 16.3072 52.2455 16.1171C52.401 15.927 52.522 15.5469 52.6084 14.9766L54.2413 4.68646C54.3796 3.82247 54.1549 3.39046 53.5674 3.39046H52.8158Z"
      fill="#D7D7D7"
    />
    <path
      d="M62.156 19.6162L62.3115 16.3763H60.2638L59.4603 19.6162H56.4277L61.8449 0.331922H65.8107L65.37 19.6162H62.156ZM61.1192 13.0586H62.467L62.7521 6.55269L61.1192 13.0586Z"
      fill="#D7D7D7"
    />
    <path
      d="M68.0161 0.331922H76.1549L75.6106 3.72743H73.2519L70.7377 19.6162H67.394L69.9082 3.72743H67.4718L68.0161 0.331922Z"
      fill="#D7D7D7"
    />
    <path
      d="M78.7214 19.6162L78.8769 16.3763H76.8292L76.0257 19.6162H72.9931L78.4103 0.331922H82.376L81.9354 19.6162H78.7214ZM77.6846 13.0586H79.0324L79.3175 6.55269L77.6846 13.0586Z"
      fill="#D7D7D7"
    />
    <path
      d="M88.834 19.2843L91.8925 0H96.6877C97.8627 0 98.7008 0.276474 99.2019 0.82943C99.7203 1.3651 99.8758 2.23774 99.6685 3.44733L99.2797 5.90971C99.0377 7.44761 98.3552 8.38073 97.232 8.70905C98.407 9.14104 98.8822 10.1519 98.6576 11.7417L98.1651 14.9557C97.9059 16.4936 97.5171 17.5995 96.9987 18.2734C96.4976 18.9474 95.7459 19.2843 94.7437 19.2843H88.834ZM94.329 10.653H93.5514L92.6442 16.2258H93.6032C93.9143 16.2258 94.1476 16.1567 94.3031 16.0184C94.4586 15.8802 94.5795 15.5519 94.6659 15.0335L95.1584 11.9231C95.2275 11.4911 95.2016 11.1714 95.0807 10.9641C94.9597 10.7567 94.7091 10.653 94.329 10.653ZM95.5213 2.95486H94.7696L94.0439 7.54266H94.8733C95.1498 7.54266 95.3571 7.47354 95.4954 7.3353C95.6509 7.19706 95.7718 6.92922 95.8582 6.53178L96.273 3.96572C96.3766 3.29181 96.1261 2.95486 95.5213 2.95486Z"
      fill="#00C1F5"
    />
    <path
      d="M104.185 19.2843L104.341 16.0443H102.293L101.49 19.2843H98.4569L103.874 0H107.84L107.399 19.2843H104.185ZM103.148 12.7266H104.496L104.781 6.22075L103.148 12.7266Z"
      fill="#00C1F5"
    />
    <path
      d="M110.045 0H118.184L117.64 3.39549H115.281L112.767 19.2843H109.423L111.937 3.39549H109.501L110.045 0Z"
      fill="#00C1F5"
    />
    <path
      d="M119.53 0H127.669L127.125 3.39549H124.766L122.252 19.2843H118.908L121.422 3.39549H118.986L119.53 0Z"
      fill="#00C1F5"
    />
    <path
      d="M129.2 0H132.543L130.029 15.8111H133.606L133.036 19.2843H126.141L129.2 0Z"
      fill="#00C1F5"
    />
    <path
      d="M137.647 0H144.775L144.231 3.39549H140.472L139.798 7.59449H142.805L142.235 11.0677H139.228L138.476 15.8111H142.468L141.898 19.2843H134.588L137.647 0Z"
      fill="#00C1F5"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconActivityLogo',
}
</script>
