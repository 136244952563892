<style lang="scss">
.user-badge {
  @apply flex items-center;
  font-size: 18px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.7);

  &:hover {
    color: rgba(255, 255, 255, 1);
  }

  &:focus {
    outline: none;
  }

  &__photo {
    @apply rounded-full bg-contain bg-no-repeat bg-center ml-4;
    --size: 38px;
    height: var(--size);
    width: var(--size);
  }
}

.tippy-tooltip {
  padding: 12px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 4px;
  font-size: 14px;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.7);
  .tippy-arrow {
    display: none;
  }
}
.user-menu {
  @apply relative;
  &__item {
    &:hover {
      color: rgba(255, 255, 255, 1);
    }
  }
  &__divider {
    @apply w-full mt-4 mb-2;
    height: 1px;
    background: rgba(255, 255, 255, 0.2);
  }
  &__custom-arrow {
    @apply absolute;
    right: 0;
    top: -20px;
  }
}
</style>

<template>
  <button class="user-badge" name="menuTrigger">
    <div for="user-menu">{{ user.username }}</div>
    <div
      id="user-menu"
      class="user-badge__photo"
      :style="{ backgroundImage: `url('${user.photo}')` }"
    />
    <tippy
      to="menuTrigger"
      arrow
      trigger="click"
      placement="bottom-end"
      interactive
      animation="perspective"
    >
      <div class="user-menu">
        <icon-arrow class="user-menu__custom-arrow" />
        <a
          href="https://id.geecko.com/profile"
          target="_blank"
          class="user-menu__item block"
          >Перейти в Geecko ID</a
        >
        <div class="user-menu__divider"></div>
        <button class="user-menu__item flex items-center focus:outline-none" @click="logout">
          <icon-exit />Выйти из аккаунта
        </button>
      </div>
    </tippy>
  </button>
</template>

<script>
import useUser from '../composables/useUser'
import IconArrow from './Icons/IconArrow.vue'
import IconExit from './Icons/IconExit.vue'
export default {
  name: 'UserBadge',
  components: { IconExit, IconArrow },
  data() {
    return {}
  },
  setup() {
    const { user, logout } = useUser()
    return { user, logout }
  },
  props: {},
  created() {},
  mounted() {},
  methods: {},
  computed: {},
}
</script>
