<style lang="scss"></style>

<template>
  <button
    :class="{
      'btn-login': type === 'primary',
      'btn-login-ghost': type === 'ghost',
      'btn-login-blank': type === 'blank',
    }"
    @click="gameAuthShow = true"
  >
    <slot>
      <icon-geecko-rounded />Участвовать
    </slot>
    <game-auth v-if="gameAuthShow" @done="nextStage()" @closed="gameAuthShow = false"/>
  </button>
</template>

<script>
import { ref } from '@vue/composition-api'
import GameAuth from '@/components/GameAuth.vue'
import IconGeeckoRounded from './Icons/IconGeeckoRounded.vue'

export default {
  name: 'ButtonAuth',
  components: { GameAuth, IconGeeckoRounded },
  data() {
    return {}
  },
  setup(props, ctx) {
    const gameAuthShow = ref(false)
    const nextStage = () => {
      gameAuthShow.value = true
    }
    return { gameAuthShow, nextStage }
  },
  props: {
    type: {
      type: String,
      default: 'primary',
      validator: function (value) {
        return ['primary', 'ghost', 'blank'].indexOf(value) !== -1
      },
    },
  },
  created() {},
  mounted() {},
  methods: {},
  computed: {},
}
</script>
