<style lang="scss" scoped>
.footer {
  &-container {
    @apply mx-auto my-0 px-10;
    max-width: 1280px;
  }
  &-content {
    @apply relative flex flex-wrap flex-col text-left;
    padding-top: 36px;
    padding-bottom: 28px;
    padding-left: 144px;
    .geecko-logo {
      position: absolute;
      top: 32px;
      left: 0;
    }
    .footer-link {
      @apply mb-2 sm:mb-0 flex flex-row;
      font-size: 13px;
      line-height: 16px;
      color: #aaaaaa;
      font-weight: 500;
      margin-right: 24px;
      text-decoration: none;
      &:hover {
        color: #fff;
      }
    }
    .links {
      @apply flex flex-col sm:flex-row sm:pr-30;
    }
    p {
      margin-top: 21px;
      font-size: 10px;
      line-height: 16px;
      color: #aaaaaa;
      max-width: 513px;
    }
    .footer-rights {
      @apply absolute text-right;
      top: 36px;
      right: 0;
      font-size: 10px;
      line-height: 16px;
      color: #9fa8ad;
    }
  }
}
@media (max-width: 576px) {
  .footer-rights {
    left: 0;
  }
  .footer-link {
    flex-direction: column;
  }
  .footer {
    &-content {
      padding-top: 80px;
      flex-direction: column;
      padding-left: 0;
      .links {
        flex-direction: column;
        max-width: none;
        padding-right: 0;
      }
    }
  }
}
</style>

<template>
  <div class="footer-container">
    <div class="footer-content">
      <div class="links">
        <icon-geecko class="geecko-logo" />
        <a
          v-for="{ title, link } in links"
          :key="title"
          :href="link"
          class="footer-link"
          target="_blank"
          >{{ title }}</a
        >
      </div>
      <div>
        <p>
          ООО «Смарт Гико». 125008, Россия, г. Москва, проезд Черепановых, д.
          36, ОГРН: 1187746655359, ИНН/КПП: 7743264341/774301001
        </p>
      </div>
      <div class="footer-rights">© 2021 Права защищены</div>
    </div>
  </div>
</template>
<script>
import IconGeecko from './Icons/IconGeecko.vue'
import { DOCS } from '@/consts.js'

export default {
  components: { IconGeecko },
  name: 'Footer',
  data() {
    return {
      links: [
        {
          title: 'Правила проведения',
          link: DOCS.RULES,
        },
        {
          title: 'Пользовательское соглашение',
          link: DOCS.AGREEMENT,
        },
        {
          title: 'Согласие на обработку ПД',
          link: DOCS.PRIVACY,
        },
      ],
    }
  },
}
</script>
