<template>
  <svg
    width="13"
    height="8"
    viewBox="0 0 13 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#arrow)">
      <path
        d="M5.76872 0.784016C6.1641 0.36008 6.83595 0.360081 7.23133 0.784017L14.0245 8.06785C14.6208 8.70716 14.1674 9.74989 13.2932 9.74989H-0.293185C-1.16739 9.74989 -1.62074 8.70716 -1.02449 8.06784L5.76872 0.784016Z"
        fill="white"
        fill-opacity="0.05"
      />
      <path
        d="M6.86568 1.12504L13.6589 8.40887C13.957 8.72853 13.7303 9.24989 13.2932 9.24989H-0.293185C-0.730287 9.24989 -0.956963 8.72852 -0.658839 8.40887L6.13437 1.12504C6.33206 0.913073 6.66799 0.913072 6.86568 1.12504Z"
        stroke="white"
        stroke-opacity="0.25"
      />
    </g>
    <defs>
      <clipPath id="arrow">
        <rect width="13" height="8" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'IconArrow',
  components: {},
  data() {
    return {}
  },
  props: {},
  created() {},
  mounted() {},
  methods: {},
  computed: {},
}
</script>
